import React from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import { flexColumn } from "../styles/Flex";

function Loader({ open, success }) {
  return (
    <Popup
      trigger={""}
      position="top left"
      defaultOpen={false}
      open={open}
      className="loaderPopup"
    >
      {success ? (
        <div style={flexColumn}>
          <p style={{ color: "black" }}>Inscription réussit</p>
          <Link to="/compte">OK</Link>
        </div>
      ) : (
        <div className="loader"></div>
      )}
    </Popup>
  );
}

export default Loader;
