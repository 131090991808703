import React, { useState } from "react";
import { Row, RowParagraphe, RowTitle } from "../Component/bsComponent/Row";
import Footer from "../Component/Footer";
import { flexRowCenter } from "../styles/Flex";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../store/actions";
import { AppAuth, db } from "../firebase/config";
import Loader from "../Component/Loader";

function Inscription() {
  const [User, setUser] = useState({});
  const [Open, setOpen] = useState(false);
  const [Success, setSuccess] = useState(false);
  // const IslogIn = useSelector((state) => state.IslogIn);
  //   const LoggedUser = useSelector((state) => state.User);
  const dispatch = useDispatch();

  const inscription = (Infos) => {
    setOpen(true);
    createUserWithEmailAndPassword(AppAuth, Infos.email, Infos.password).then(
      async (res) => {
        try {
          const docRef = await addDoc(collection(db, "users"), {
            nom: Infos.nom,
            prenom: Infos.prenom,
            email: Infos.email,
            password: Infos.password,
          });
          dispatch(allActions.LogInAction.LogIn());
          dispatch(allActions.userAction.SetUser(Infos));
          setSuccess(true);
        } catch (err) {
          alert(err);
          console.log(err);
          console.log(err);
        }
      }
    );
  };

  return (
    <>
      <div style={{ marginTop: "200px", marginBottom: "200px" }}>
        <div className="container">
          <RowTitle title="INSCRIPTION" py={1} />
          <RowParagraphe
            paragraphe="Veuillez remplir les champs suivants :"
            py={1}
          />
          <Row py={1}>
            <div style={flexRowCenter}>
              <input
                style={{ width: "35%" }}
                className="input-1"
                type="text"
                placeholder="Prénom"
                onChange={(e) => setUser({ ...User, prenom: e.target.value })}
              ></input>
            </div>
          </Row>
          <Row py={1}>
            <div style={flexRowCenter}>
              <input
                style={{ width: "35%" }}
                className="input-1"
                type="text"
                placeholder="Nom de famille"
                onChange={(e) => setUser({ ...User, nom: e.target.value })}
              ></input>
            </div>
          </Row>
          <Row py={1}>
            <div style={flexRowCenter}>
              <input
                style={{ width: "35%" }}
                className="input-1"
                type="text"
                placeholder="Email"
                onChange={(e) => setUser({ ...User, email: e.target.value })}
              ></input>
            </div>
          </Row>
          <Row py={1}>
            <div style={flexRowCenter}>
              <input
                style={{ width: "35%" }}
                className="input-1"
                type="password"
                placeholder="Mot de passe"
                onChange={(e) => setUser({ ...User, password: e.target.value })}
              ></input>
            </div>
          </Row>
          <Row py={1}>
            <div style={flexRowCenter}>
              <button
                className="btn-1"
                style={{ width: "35%", textAlign: "center" }}
                onClick={() => inscription(User)}
              >
                créer mon compte
              </button>
            </div>
          </Row>
        </div>
      </div>
      <Footer />
      <Loader open={Open} success={Success} />
    </>
  );
}

export default Inscription;
