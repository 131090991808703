import React from "react";
import Popup from "reactjs-popup";

function WelcomeMsg({ open }) {
  return (
    <Popup trigger={""} position="top left" defaultOpen={true} open={open}>
      {(close) => (
        <div>
          <a className="close" onClick={close}>
            &times;
          </a>
        </div>
      )}
      <img src="logo_3.png" />
    </Popup>
  );
}

export default WelcomeMsg;
