import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, RowParagraphe, RowTitle } from "../Component/bsComponent/Row";
import Footer from "../Component/Footer";
import { AppAuth, db } from "../firebase/config";
import { flexRowCenter } from "../styles/Flex";
import { useDispatch } from "react-redux";
import allActions from "../store/actions";
import { useNavigate } from "react-router-dom";

function Connexion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const connexion = () => {
    signInWithEmailAndPassword(AppAuth, Email, Password)
      .then(async (userCredential) => {
        const querySnapshot = await getDocs(
          query(collection(db, "users"), where("email", "==", Email))
        );
        querySnapshot.forEach((doc) => {
          dispatch(allActions.userAction.SetUser(doc.data()));
          dispatch(allActions.LogInAction.LogIn());
          navigate("/compte", { replace: true });
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(error.message);
      });
  };

  return (
    <>
      <div style={{ marginTop: "200px", marginBottom: "200px" }}>
        <div className="container">
          <RowTitle title="CONNEXION" py={1} />
          <RowParagraphe
            paragraphe="Veuillez indiquer votre email et mot de passe :"
            py={1}
          />
          <Row py={1}>
            <div style={flexRowCenter}>
              <input
                style={{ width: "35%" }}
                className="input-1"
                type="text"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </div>
          </Row>
          <Row py={1}>
            <div style={flexRowCenter}>
              <input
                style={{ width: "35%" }}
                className="input-1"
                type="password"
                placeholder="Mot de passe"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              ></input>
            </div>
          </Row>
          <Row py={1}>
            <div style={flexRowCenter}>
              <button
                className="btn-1"
                style={{ width: "35%" }}
                onClick={() => {
                  connexion();
                }}
              >
                CONNEXION
              </button>
            </div>
          </Row>
          <Row py={1}>
            <div style={flexRowCenter}>
              <p>
                Vous n'avez pas de compte ?
                <Link to="/inscription"> En créer un maintenant</Link>
              </p>
            </div>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Connexion;
