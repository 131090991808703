import React from "react";

function Page2() {
  return (
    <div style={{ marginTop: "200px" }}>
      <h1>page2</h1>
    </div>
  );
}

export default Page2;
