const product = (
  state = [
    {
      img: "https://images.pexels.com/photos/10037708/pexels-photo-10037708.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      nom: "BRAND",
    },
    {
      img: "https://images.pexels.com/photos/12513230/pexels-photo-12513230.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      nom: "BRAND",
    },
  ],
  action
) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default product;
