import React, { useEffect, useState } from "react";

function Banner({ src }) {
  const [width, setWindowWidth] = useState(0);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const responsive = {
    mobile: width < 768,
  };

  const styleObj = (images) => {
    return {
      width: "100vw",
      height: responsive.mobile ? "50vh" : "100vh",
      overflow: "hidden",
      backgroundImage: "url(" + images + ")",
      backgroundAttachment: "fixed",
      backgroundPosition: "0 20%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      // animationName: "ImgZoom ",
      // animationDuration: "3s",
    };
  };

  return <div style={styleObj(src)}></div>;
}

export default Banner;
