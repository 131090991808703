import React from "react";
import { Link } from "react-router-dom";
import {
  flexColumn,
  flexColumnLeft,
  flexColumnRight,
  flexRowAround,
  flexRowBetween,
  flexRowCenter,
} from "../styles/Flex";

function PanierMenu({ onclick }) {
  return (
    <div className="overlay">
      <div className="panierMenu px-5" style={flexColumnRight}>
        <div style={flexRowAround} className="w-100">
          <h3>PANIER</h3>
          <i className="click bi bi-x p-4 fs3" onClick={onclick}></i>
        </div>
        <div style={flexRowCenter} className="h-80 w-100">
          <h3>votre panier est vide</h3>
        </div>
      </div>
    </div>
  );
}

export default PanierMenu;
