import React from "react";

function Panier() {
  return (
    <div style={{ marginTop: "200px" }}>
      <h1>Panier</h1>
    </div>
  );
}

export default Panier;
