import React, { useState } from "react";
import { useSelector } from "react-redux";
import Fuse from "fuse.js";
import ProductCards from "../Component/ProductCard";
import { Row } from "../Component/bsComponent/Row";

function Search({ cancelFunction, top }) {
  const Products = useSelector((state) => state.product);
  const option = { findAllMatches: false, keys: ["nom"] };
  const fuse = new Fuse(Products, option);
  const [pattern, setPattern] = useState("");
  const [res, setRes] = useState(null);

  return (
    <div>
      <div className="searchSection" style={{ top: top }}>
        <i className="bi bi-search fs3"></i>
        <input
          style={{ width: "90%" }}
          className="input-1"
          type="text"
          placeholder="Rechercher"
          onChange={(e) => {
            setPattern(e.target.value);
            setRes(fuse.search(pattern));
          }}
        ></input>
        <i className="bi bi-x fs4 click" onClick={cancelFunction}></i>
      </div>
      {res != null && (
        <div className="container">
          {res.length == 0 ? (
            "aucun résultat trouvé"
          ) : (
            <>
              <div className="row">{res.length} Résultat(s)</div>
              <Row py={1}>
                {res.map((item, index) => {
                  return (
                    <div className="col-sm-6">
                      <ProductCards
                        key={index}
                        srcImg={item.item.img}
                        productName={item.item.nom}
                      />
                    </div>
                  );
                })}
              </Row>
            </>
          )}
        </div>
      )}
    </div>
  );
}

//fuse.search(pattern)
export default Search;
