import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import AddArticle from "../Component/AdminComponent/AddArticle";
import Article from "../Component/Article";
import { Row, RowTitle } from "../Component/bsComponent/Row";
import { db, storage } from "../firebase/config";
import { flexColumn, flexRowCenter } from "../styles/Flex";

function Admin() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [article, setArticle] = useState([]);
  const [img, setImg] = useState([]);
  const [access, setAccess] = useState(false);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  useEffect(() => {
    const taskColRef = query(
      collection(db, "articles"),
      orderBy("created", "desc")
    );
    onSnapshot(taskColRef, (snapshot) => {
      setArticle(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    });
  }, []);

  const checkAdmin = async () => {
    var key = user + pass;
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("SpecialKey", "==", key))
      );
      if (
        querySnapshot != null ||
        querySnapshot != undefined ||
        querySnapshot != []
      ) {
        querySnapshot.forEach((doc) => {
          setAccess(true);
        });
      } else {
        alert("accès incorrects");
      }
    } catch (err) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkAdmin();
  };

  return access ? (
    <div style={{ marginTop: "200px" }}>
      <RowTitle title="Admin" py={1} />
      <Row py={1}>
        <div style={flexRowCenter}>
          <button onClick={() => setOpenAddModal(true)}>
            Ajouter un article +
          </button>
        </div>
      </Row>

      <div className="container">
        {article.map((task) => (
          <Row styles={{ borderBottom: "1px solid grey" }} key={task.id}>
            <Article
              id={task.id}
              title={task.data.title}
              description={task.data.description}
              price={task.data.price}
              picture={task.data.picture}
            />
          </Row>
        ))}
      </div>

      {openAddModal && (
        <AddArticle
          onClose={() => setOpenAddModal(false)}
          open={openAddModal}
        />
      )}
    </div>
  ) : (
    <div style={{ marginTop: "200px" }}>
      <h1 style={{ textAlign: "center" }}>
        Connectez vous à l'espace administrateur
      </h1>
      <form onSubmit={handleSubmit} style={flexColumn} name="addTask">
        <input
          type="text"
          name="Username"
          onChange={(e) => setUser(e.target.value)}
          value={user}
          placeholder="Username"
        />
        <input
          type="password"
          name="pass"
          onChange={(e) => setPass(e.target.value)}
          value={pass}
          placeholder="Mot de passe"
        />

        <button type="submit">Valider</button>
      </form>
    </div>
  );
}

export default Admin;
