// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDHeUlQwI5vptQDYjp52plhCpEifLSlmIE",
  authDomain: "empreinteparis-abfd9.firebaseapp.com",
  projectId: "empreinteparis-abfd9",
  storageBucket: "empreinteparis-abfd9.appspot.com",
  messagingSenderId: "268870496562",
  appId: "1:268870496562:web:f970d3930b16933252272b",
  measurementId: "G-J3YR7XBDS9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const AppAuth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, AppAuth, db, storage };
