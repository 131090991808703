import {
  addDoc,
  collection,
  doc,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import { db, storage } from "../../firebase/config";
import Modal from "../Modal";

function AddArticle({ onClose, open }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [picture, setPicture] = useState({});

  /* function to add new task to firestore */
  const handleSubmit = (e) => {
    e.preventDefault();

    uploadBytes(ref(storage, `article/${picture.name}`), picture).then(
      async (snapshot) => {
        try {
          await addDoc(collection(db, "articles"), {
            title: title,
            description: description,
            price: price,
            picture: `article/${picture.name}`,
            created: Timestamp.now(),
          });
          onClose();
        } catch (err) {
          alert(err);
        }
      }
    );
  };

  return (
    <>
      <div className="container"></div>
      <Modal modalLable="Ajouter un article" onClose={onClose} open={open}>
        <form onSubmit={handleSubmit} className="addTask" name="addTask">
          <input
            type="text"
            name="title"
            onChange={(e) => setTitle(e.target.value.toUpperCase())}
            value={title}
            placeholder="Titre de l'article"
          />
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Entrer la description du produit"
            value={description}
          ></textarea>
          <input
            type="text"
            name="price"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
            placeholder="Prix de l'article en euro"
          />
          <label>Ajouter l'image du produit</label>
          <input type="file" onChange={(e) => setPicture(e.target.files[0])} />
          <button type="submit">Valider</button>
        </form>
      </Modal>
    </>
  );
}

export default AddArticle;
