import React from "react";
import { flexColumn, flexRowCenter } from "../styles/Flex";

function ProductCards({ srcImg, productName }) {
  return (
    <div style={flexColumn} className="ProductCard">
      <div className="ProductCard-img">
        <img src={srcImg} />
        <div className="ProductCard-img-anim" style={flexRowCenter}>
          <h3>Ajouter au panier</h3>
        </div>
      </div>
      <h3>{productName}</h3>
    </div>
  );
}

export default ProductCards;
