import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row } from "../Component/bsComponent/Row";
import Footer from "../Component/Footer";
import allActions from "../store/actions";

function Compte(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LoggedUser = useSelector((state) => state.User);

  return (
    <>
      <div
        style={{ marginTop: "200px", marginBottom: "200px" }}
        className="container"
      >
        <Row py={1}>
          <div>
            <button
              className="btn-1"
              style={{ textTransform: "uppercase" }}
              onClick={() => {
                dispatch(allActions.LogInAction.LogOut());
                dispatch(allActions.userAction.SetUser({}));
                navigate("/connexion", { replace: true });
              }}
            >
              déCONNEXION
            </button>
          </div>
        </Row>
        <Row py={1}>
          <h2>MON COMPTE</h2>
          <p>bienvenue {LoggedUser.infos.prenom}</p>
        </Row>
        <Row py={5} styles={{ borderBottom: "0.1px grey solid" }}>
          <h4>Mes commandes</h4>
        </Row>
        <Row>
          <p>Vous n'avez passé aucune commande</p>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default Compte;
