import React from "react";
import {
  flexColumn,
  flexColumnLeft,
  flexLeft,
  flexRowAround,
  flexRowCenter,
} from "../styles/Flex";
import { Row } from "./bsComponent/Row";
import SocialLink from "./SocialLink";

function Footer() {
  const fontSize = {
    fontSize: "0.8rem",
  };

  return (
    <div className="container-fluid">
      <Row py={3} styles={{ borderTop: "0.1px grey solid" }}>
        <div className="col-sm-4 py-2" style={flexColumnLeft}>
          <h5>INFORMATION</h5>
          <p style={fontSize}>L'empreinte paris</p>
          <p style={fontSize}>Situation : Paris France</p>
          <p style={fontSize}>Mail : Empreinte.paris75@gmail.com</p>
          {/* <p style={fontSize}>Tél : XXXXXXX</p> */}
        </div>
        <div className="col-sm-4 py-2" style={flexColumnLeft}>
          <h5>NEWSLETTER</h5>
          <p style={fontSize}>
            Inscrivez-vous pour être alerté(e) en avant-première des
            exclusivités !
          </p>
          <input
            className="input-1"
            type="text"
            placeholder="Entrez votre mail"
          ></input>
          <button className="btn-1">S'inscrire</button>
        </div>
        <div className="col-sm-4 py-2" style={flexColumnLeft}>
          <h5 style={{ textTransform: "uppercase" }}>RÉSEAUX SOCIAUX</h5>
          <div style={flexRowAround}>
            <SocialLink
              className="footerSocial"
              icon="bi-instagram"
              href="https://www.instagram.com/l_empreinte_pariis_/"
            />
          </div>
        </div>
      </Row>
      <Row py={4}>
        <p style={{ textAlign: "center" }}>
          Site réalisé par{" "}
          <a
            href="https://malickruru.github.io/"
            title="Rencontrer le programmeur"
          >
            RuRu
          </a>{" "}
          & <a href="tel:002250789396903 "> Marc </a>
        </p>
      </Row>
    </div>
  );
}

export default Footer;
