import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// window.addEventListener("scroll", () => {
//   if (window.scrollY > 20) {
//     document.querySelector(".header").style.backgroundColor = "#000";
//     document.querySelector(".header").style.borderBottom = "0.1px grey solid";
//     document.querySelector(".header").style.color = "#fff";
//   } else {
//     document.querySelector(".header").style.backgroundColor =
//       "rgba(0, 0, 0, 0.5)";
//     document.querySelector(".header").style.borderBottom = "none";
//   }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
