import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../firebase/config";
import Modal from "../Modal";

function EditArticle({
  open,
  onClose,
  toEditTitle,
  toEditDescription,
  toEditPrice,
  toEditPicture,
  id,
}) {
  const [title, setTitle] = useState(toEditTitle);
  const [description, setDescription] = useState(toEditDescription);
  const [price, setPrice] = useState(toEditPrice);
  const [picture, setPicture] = useState(toEditPicture);

  /* function to update firestore */
  const handleUpdate = async (e) => {
    e.preventDefault();
    const taskDocRef = doc(db, "articles", id);
    try {
      await updateDoc(taskDocRef, {
        title: title,
        description: description,
        price: price,
      });
      onClose();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Modal modalLable="Modifier un Article" onClose={onClose} open={open}>
      <form onSubmit={handleUpdate} className="editTask">
        <input
          type="text"
          name="title"
          onChange={(e) => setTitle(e.target.value.toUpperCase())}
          value={title}
        />
        <textarea
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        ></textarea>
        <input
          type="text"
          name="price"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
        />
        <img src={picture} width={100} />
        <button type="submit">Modifier</button>
      </form>
    </Modal>
  );
}

export default EditArticle;
