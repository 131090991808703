import productAction from "./productAction";
import LogInAction from "./LogInAction";
import userAction from "./UserAction";

const allActions = {
  productAction,
  LogInAction,
  userAction,
};

export default allActions;
