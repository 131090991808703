import React from "react";

function SocialLink({ href, iconProvider, icon, className }) {
  var CssClass = "";

  iconProvider
    ? (CssClass = iconProvider + " " + icon)
    : (CssClass = "bi " + icon);

  return (
    <a href={href} className={className}>
      <i className={CssClass}></i>
    </a>
  );
}

export default SocialLink;
