import { deleteDoc, doc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { db, storage } from "../firebase/config";
import EditArticle from "./AdminComponent/EditArticle";

function Article({ id, title, description, price, picture }) {
  const [openEdit, setOpenEdit] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    getDownloadURL(ref(storage, picture)).then((url) => {
      setUrl(url);
    });
  }, []);

  const handleClose = () => {
    setOpenEdit(false);
  };

  /* function to delete a document from firstore */
  const handleDelete = async () => {
    const taskDocRef = doc(db, "articles", id);
    try {
      await deleteDoc(taskDocRef);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div>
      <div>
        <div className="article-body">
          <div className="article-body-left">
            <img src={url} />
          </div>
          <div className="article-body-right">
            <b>Titre :</b>
            {title}
            <br />
            <b>description :</b>
            {description}
            <br />
            <b>Prix :</b>
            {price} €
            <br />
          </div>
        </div>
        <div className="article-body-buttons">
          <button onClick={() => setOpenEdit(true)}>Modifier</button>
          <button onClick={handleDelete}>Delete</button>
        </div>
      </div>
      {openEdit && (
        <EditArticle
          onClose={handleClose}
          toEditTitle={title}
          toEditDescription={description}
          toEditPrice={price}
          toEditPicture={picture}
          open={openEdit}
          id={id}
        />
      )}
    </div>
  );
}

export default Article;
