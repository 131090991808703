import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Accueil from "../page/Accueil";
import Page2 from "../page/page2";
import Panier from "../page/Panier";
import Connexion from "../page/Connexion";
import Inscription from "../page/Inscription";
import Compte from "../page/Compte";
import Admin from "../page/Admin";

function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Accueil />} />
          <Route path="Page2" element={<Page2 />} />
          <Route path="Panier" element={<Panier />} />
          <Route path="connexion" element={<Connexion />} />
          <Route path="inscription" element={<Inscription />} />
          <Route path="compte" element={<Compte />} />
          <Route path="admin" element={<Admin />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
