import React from "react";
import { Link } from "react-router-dom";
import { flexColumn} from "../styles/Flex";

function MobileMenu({ onclick }) {
  return (
    <div className="overlay">
      <div className="mobileMenu" style={flexColumn}>
        <i className="click bi bi-x p-4 fs3" onClick={onclick}></i>
        <Link className="link" to="/">
          Accueil
        </Link>

        <Link className="link" to="connexion">
          Se connecter
        </Link>
      </div>
    </div>
  );
}

export default MobileMenu;
