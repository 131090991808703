import React, { useEffect, useState } from "react";
import Banner from "../Component/Banner";
import { RowTitle, Row } from "../Component/bsComponent/Row";
import WelcomeMsg from "../Component/WelcomeMsg";
import ProductCards from "../Component/ProductCard";
import Video from "../Component/Video";
import Footer from "../Component/Footer";
import { useSelector } from "react-redux";
// const Products = [
//   {
//     img: "https://images.pexels.com/photos/10037708/pexels-photo-10037708.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
//     nom: "Produit 1",
//   },
//   {
//     img: "https://images.pexels.com/photos/12513230/pexels-photo-12513230.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
//     nom: "Produit 2",
//   },
// ];

function Accueil() {
  const Products = useSelector((state) => state.product);
  const [OpenMsg, setOpen] = useState(true);
  const [width, setWindowWidth] = useState(0);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const responsive = {
    mobile: width < 768,
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  }, []);
  return (
    <>
      <Banner src="Image/bg.jpeg" />

      <div className="container">
        <RowTitle
          py={"5"}
          title="Nouveau produits disponibles dès maintenant"
        />
        <Row py={1}>
          {Products.map((item, index) => {
            return (
              <div className="col-sm-6">
                <ProductCards
                  key={index}
                  srcImg={item.img}
                  productName={item.nom}
                />
              </div>
            );
          })}
        </Row>
      </div>
      <div className="container-fluid m-0 p-0">
        <Row
          py={3}
          styles={responsive.mobile ? { height: "50vh" } : { height: "80vh" }}
        >
          <Video
            src="video/trailer.mp4"
            styles={{ objectFit: "cover", height: "100%" }}
          />
        </Row>
      </div>
      <Footer />
      <WelcomeMsg open={OpenMsg} />
    </>
  );
}

export default Accueil;
