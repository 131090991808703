import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import MobileMenu from "./mobileMenu";
import PanierMenu from "./Panier";
import Search from "./Search";
import { useSelector } from "react-redux";

function Header() {
  const IslogIn = useSelector((state) => state.IslogIn);
  const [MenuOpen, setMenuOpen] = useState(false);
  const [PanierOpen, setPanierOpen] = useState(false);
  const [SearchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (MenuOpen) {
      gsap.to(".mobileMenu", {
        transform: "translateX(0)",
        duration: 1,
      });
    } else {
      gsap.to(".mobileMenu", {
        transform: "translateX(-100%)",
        duration: 1,
      });
    }
  }, [MenuOpen]);

  useEffect(() => {
    if (PanierOpen) {
      gsap.to(".panierMenu", {
        transform: "translateX(20%)",
        duration: 1,
      });
    } else {
      gsap.to(".panierMenu", {
        transform: "translateX(0%)",
        duration: 1,
      });
    }
  }, [PanierOpen]);

  return (
    <>
      <div className="header " style={{ overflowY: "auto" }}>
        <div className=" d-flex justify-content-around align-items-center">
          <div className="mobile-only">
            <i
              className="bi bi-list fs3 click"
              onClick={() => {
                setMenuOpen(true);
              }}
            ></i>
          </div>
          <div className="desktop-only ">
            <Link className="link" style={{ paddingLeft: 0 }} to="/">
              Accueil
            </Link>
          </div>

          <div>
            <Link to="/">
              <div className="logo-wrapper">
              <img className="logo" src="logo_4.png" />
              </div>
            </Link>
          </div>

          <div>
            <Link
              className="link desktop-only"
              to={IslogIn ? "compte" : "connexion"}
            >
              <i className="bi bi-person-fill "></i>
            </Link>
            <i
              className="bi bi-search click link"
              onClick={() => setSearchOpen(true)}
            ></i>
            <i
              className="bi bi-basket3 click link"
              onClick={() => setPanierOpen(true)}
            ></i>
          </div>
        </div>
        {SearchOpen && (
          <Search
            cancelFunction={() => setSearchOpen(false)}
            top={document.querySelector(".header").offsetHeight}
          />
        )}
      </div>

      {MenuOpen && (
        <MobileMenu
          onclick={() => {
            setMenuOpen(false);
          }}
        />
      )}
      {PanierOpen && (
        <PanierMenu
          onclick={() => {
            setPanierOpen(false);
          }}
        />
      )}

      <Outlet />
    </>
  );
}

export default Header;
