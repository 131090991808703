import product from "./product";
import IslogIn from "./Log";
import User from "./User";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  product,
  IslogIn,
  User,
});

export default rootReducer;
