const LogIn = () => {
  return {
    type: "LOGIN",
  };
};

const LogOut = () => {
  return {
    type: "LOGOUT",
  };
};

export default { LogIn, LogOut };
