import React from "react";

var pyClass;

function RowTitle({ py, title }) {
  pyClass = "py-" + py;

  return (
    <div className={"row " + pyClass}>
      <h1 style={{ textAlign: "center" }}>{title}</h1>
    </div>
  );
}

function RowParagraphe({ py, paragraphe }) {
  pyClass = "py-" + py;

  return (
    <div className={"row " + pyClass}>
      <p style={{ textAlign: "center" }}>{paragraphe}</p>
    </div>
  );
}

function Row({ py, children, styles }) {
  pyClass = "py-" + py;

  return (
    <div className={"row " + pyClass} style={styles}>
      {children}
    </div>
  );
}

export { RowTitle, Row, RowParagraphe };
